import React, { useEffect, useState } from "react";
import { Row, Col, Table, Button, Tooltip, Popconfirm, Modal } from "antd";
import {
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import agros from "../../../const/api";
import ReactPlayer from "react-player";

const Cameras = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [camera,setCamera] = useState(null);
  const embed = "http://10.10.1.97:2000/";
  useEffect(() => {
    const getData = () => {
      agros.get("Camera").then((res) => {
        setData(
          res.data.map((r, index) => {
            return {
              ...r,
              key: index,
            };
          })
        );
      }).catch((err) =>{
        console.log(err);
      }
      );
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ t]);
  console.log(camera);
  return (
    <div className="all">
      <Row gutter={[10, 10]}>
      {
                camera &&
                <div className="player-card" onClick={() => setCamera(null)}>
                    <div className="player">
                        <div className="player">
                            <ReactPlayer url={camera} />
                        </div>
                    </div>
                </div>
            }
        <Col xs={24}>
          <div className="border  page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <UnorderedListOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">{t("Kameralar")}</span>
            </div>
          </div>
        </Col>
        <Col>
        <div className="cameras" style={{display:'flex',flexWrap:'wrap'}}>
            {
                data.map((item,index)=>{
                    return(
                        <div className="camera" key={index}>
                            <iframe
                                className='video-id'
                                src={item.url}//https://youtu.be/embed/XLgKHljq06M?si=flDHm-nxLC0qtxLI/embed
                                title="YouTube video player"
                                
                            ></iframe>
                            <span className="pointer" onClick={()=>setCamera(item.url)}>{item.name}</span>
                        </div>
                    )
                }
                )

            }
        </div>
        </Col>
  
      </Row>
    </div>
  );
};

export default Cameras;
